<template>
  <div>
    <ApplyMain ref="applymain" stepcurrent="4">
      <div slot="content" class="container">
        <div class="m">
          <el-form
            :model="form"
            :inline-message="true"
            :rules="rules"
            size="mini"
            label-width="100px"
            class="elform"
            ref="form"
          >
            <div class="mt">
              <h4 class="title">
                <span>经营信息</span>
              </h4>
            </div>
            <div class="mc">
              <div>
                <el-form-item
                  label="公司类型"
                  label-width="185px"
                  prop="companyType"
                >
                  <el-select
                    placeholder="请选择公司类型"
                    style="width:400px"
                    v-model="form.companyType"
                  >
                    <el-option value="" label="">请选择</el-option>
                    <el-option
                      v-for="item in companyTypedata"
                      :key="item.id"
                      :label="item.data_title"
                      :value="item.data_title"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div>
                <el-form-item
                  class="formL"
                  label="公司网址"
                  label-width="185px"
                  prop="companyWebSite"
                >
                  <el-input
                    v-model="form.companyWebSite"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>形如：https://www.jinyingu.cn/</span>
                </div>
              </div>
              <div>
                <el-form-item
                  class="formL"
                  label="年销售额(万元)"
                  label-width="185px"
                  prop="companyAnnualSales"
                >
                  <el-input
                    onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                    v-model="form.companyAnnualSales"
                    style="width:400px"
                    maxlength="9"
                  ></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>仅输入不得超过9位数的正整数</span>
                </div>
              </div>
              <div>
                <el-form-item
                  class="formL"
                  label-width="185px"
                  label="电商平台经验"
                >
                  <el-radio-group
                    v-model="form.ecommerceExperience"
                    style="width:400px"
                    size="small"
                  >
                    <el-radio :label="1">有</el-radio>
                    <el-radio :label="0">无</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div v-show="form.ecommerceExperience == 1">
                <el-form-item
                  class="formL"
                  label="网店地址"
                  label-width="185px"
                  prop="ecommerceWebSite"
                >
                  <el-input
                    v-model="form.ecommerceWebSite"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>形如：https://www.jinyingu.cn/</span>
                </div>
              </div>
              <div v-show="form.ecommerceExperience == 1">
                <el-form-item
                  class="formL"
                  label="运营人数"
                  label-width="185px"
                  prop="companyOperateNO"
                >
                  <el-input
                    onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                    v-model="form.companyOperateNO"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="商品数量" label-width="185px">
                  <el-select
                    placeholder="请选择商品数量"
                    style="width:400px"
                    v-model="form.companyGoodsCountRange"
                  >
                    <el-option
                      v-for="item in companyGoodsCountRangedata"
                      :key="item.id"
                      :label="item.data_title"
                      :value="item.data_title"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span
                    >某一商品的多种颜色、尺码或包装形式不同时，按一件商品计算。</span
                  >
                </div>
              </div>
              <div>
                <el-form-item label="平均客单价" label-width="185px">
                  <el-select
                    placeholder="请选择平均客单价"
                    style="width:400px"
                    v-model="form.customerAverageUnitPrice"
                  >
                    <el-option
                      v-for="item in customerAverageUnitPricedata"
                      :key="item.id"
                      :label="item.data_title"
                      :value="item.data_title"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>用户购物时，单次购买的平均金额。</span>
                </div>
              </div>
              <div v-if="false">
                <el-form-item
                  label="仓库情况"
                  label-width="185px"
                  prop="companyStore"
                >
                  <el-select
                    placeholder="请选择仓库情况"
                    style="width:400px"
                    v-model="form.companyStore"
                  >
                    <el-option
                      v-for="item in companyStoredata"
                      :key="item.id"
                      :label="item.data_title"
                      :value="item.data_title"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div v-if="false">
                <el-form-item
                  class="formL"
                  label="仓库地址"
                  label-width="185px"
                  prop="companyStoreAddress"
                >
                  <el-input
                    v-model="form.companyStoreAddress"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
              </div>

              <div v-if="false">
                <el-form-item
                  class="formL"
                  label="常用物流"
                  label-width="185px"
                  prop="companyUseExpress"
                >
                  <el-input
                    v-model="form.companyUseExpress"
                    style="width:400px"
                  ></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span
                    >可填写多个，用逗号分隔，如“申通，圆通”，最大50字。</span
                  >
                </div>
              </div>

              <div class="btn-group t-r">
                <el-form-item>
                  <el-button @click="onPrev()">上一步</el-button>
                  <el-button
                    class="btn"
                    @click="onsubmitForm('form')"
                    type="primary"
                    >下一步，完善类目及资质</el-button
                  >
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </ApplyMain>
  </div>
</template>
<script>
import { companyintosurveyinfo, getcompanyintosurveyinfo } from '@/api/user';
import ApplyMain from '@/components/applymain.vue';
export default {
  name: 'contact',
  components: {
    ApplyMain
  },
  data() {
    return {
      companyTypedata: [
        { id: 1, data_title: '生产厂商' },
        { id: 2, data_title: '品牌商' },
        { id: 3, data_title: '代理商' },
        { id: 4, data_title: '经销商' }
      ],
      companyGoodsCountRangedata: [
        { id: 1, data_title: '请选择' },
        { id: 2, data_title: '0-100' },
        { id: 3, data_title: '100-200' },
        { id: 4, data_title: '200-500' },
        { id: 5, data_title: '大于500' }
      ],
      customerAverageUnitPricedata: [
        { id: 1, data_title: '请选择' },
        { id: 2, data_title: '0-100' },
        { id: 3, data_title: '100-200' },
        { id: 4, data_title: '200-500' },
        { id: 5, data_title: '大于500' }
      ],
      companyStoredata: [
        { id: 1, data_title: '请选择' },
        { id: 2, data_title: '自有仓库' },
        { id: 3, data_title: '第三方仓库' },
        { id: 4, data_title: '无仓库' }
      ],
      form: {
        companyType: null,
        companyWebSite: '',
        companyAnnualSales: 0,
        ecommerceExperience: 0,
        ecommerceWebSite: null,
        companyOperateNO: 0,
        companyGoodsCountRange: '',
        customerAverageUnitPrice: '',
        companyStore: '',
        companyStoreAddress: '',
        companyUseExpress: '',
        id: 0
      },
      rules: {
        companyType: [
          { required: true, message: '请选择公司类型', trigger: 'change' }
        ],
        companyAnnualSales: [
          { required: true, message: '请输入年销售额(万元)', trigger: 'blur' }
        ],
        companyWebSite: [
          {
            pattern: /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/,
            message: '公司网址格式不正确'
          }
        ],
        companyOperateNO: [
          {
            required: true,
            type: 'number',
            validator: (rule, value, callback) => {
              let { ecommerceExperience } = this.form;
              if ((value == 0 || value == '') && ecommerceExperience == 1) {
                callback(new Error('请输入运营人数'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        ecommerceWebSite: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let { ecommerceExperience } = this.form;
              if ((value == null || value == '') && ecommerceExperience == 1) {
                callback(new Error('请输入网店地址'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          },
          {
            pattern: /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/,
            message: '网店地址格式不正确'
          }
        ]
      }
    };
  },
  methods: {
    ongetcompanyintosurveyinfo() {
      let params = {};
      getcompanyintosurveyinfo(params).then(res => {
        if (res.data.code === 1) {
          this.form = res.data.result;
        }
      });
    },
    onsubmitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = this.form;
          companyintosurveyinfo(params).then(res => {
            if (res.data.code === 1) {
              this.$router.push('/user/apply/category');
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      });
    },
    onPrev() {
      this.$router.push('/user/apply/comnewnormalbank');
    }
  },
  created() {
    this.ongetcompanyintosurveyinfo();
  }
};
</script>
<style scoped>
[class*=' el-icon-'],
[class^='el-icon-'] {
  line-height: 20px;
}
.container >>> .el-form-item__content {
  font-size: 12px;
  line-height: 30px;
  color: #666;
}
h4.title {
  font-size: 16px;
  font-family: 'Microsoft Yahei';
  line-height: 180%;
  color: #333333;
  margin-bottom: 5px;
  float: left;
  margin-right: 10px;
}
.container >>> .el-form-item {
  margin-bottom: 8px;
}
.container >>> .el-form-item__label {
  font-size: 12px;
}
.container >>> .el-input__inner {
  height: 30px;
  font-size: 12px;
  padding-left: 10px;
  line-height: 32px;
}
.container >>> .el-input--prefix .el-input__inner {
  padding-left: 30px;
}
.container .form-describe-text {
  margin-left: 185px;
  color: #999;
  top: -9px;
  line-height: 20px;
  margin-top: 9px;
  position: relative;
  font-size: 12px;
}
.container >>> .el-button {
  padding: 8px 20px;
}
.container >>> .btn {
  border: 1px solid #c6e2ff;
  background-color: #31b7f4;
}
.btn:hover,
.btn-normal:hover {
  border: 1px solid;
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.form-error-text {
  margin-left: 185px;
  color: #ff3333;
  /* top: 0px; */
  line-height: 20px;
  margin-top: 5px;
  position: relative;
  font-size: 12px;
}
.container .upload-pop-desc {
  margin-left: 0;
  top: -5px;
}
.container >>> .el-upload--picture-card {
  width: 78px;
  height: 78px;
  line-height: 78px;
}
.container .el-upload__tip {
  margin-top: 0;
  line-height: 20px;
  color: #999;
}
.container >>> .el-form-item__error--inline {
  margin-left: 0;
}
.container >>> .el-radio {
  margin-right: 16px;
}
.container >>> .el-radio .el-radio__label {
  padding-left: 5px;
}
</style>
